import React, {Component} from "react";
import picture from "../../images/new.png";

class Picture extends Component {
    render() {
        return (
            <div className="">
                <img src={picture} alt="" className="profile--picture" />
            </div>
        );
    }
}

export default Picture;